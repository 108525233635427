import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import "dayjs/locale/ar"; // Import Arabic locale if you're using Arabic
import "dayjs/locale/en"; // Import Arabic locale if you're using Arabic
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTranslation } from "react-i18next";
import { useSearchFlights } from "../../../context/SearchFlightsContext";
dayjs.extend(utc);
dayjs.extend(timezone);
export default function DatepickerContainer({
  keyName,
  title,
  startDate,
  setStartDate,
  label,
}) {
  const { removeDate } = useSearchFlights();
  const { t, i18n } = useTranslation();
  const dateStorge = dayjs(startDate, "YYYY-MM-DD");

  const userTimezone = dayjs.tz.guess();

  const handleDate = (e) => {
    setStartDate(dayjs(e).format("YYYY-MM-DD"));
  };
  // Run before rendering
  if (startDate && dayjs(startDate, "YYYY-MM-DD").isBefore(dayjs(), "day")) {
    removeDate();
  }
  // useEffect(() => {
  //   function isDateInThePast(date) {
  //     const parsedDate = dayjs(date, "DD/MM/YYYY");
  //     const today = dayjs();
  //     console.log("Parsed Date:", parsedDate.format("DD/MM/YYYY"));
  //     console.log("Today:", today.format("DD/MM/YYYY"));
  //     console.log("Is in the past:", parsedDate.isBefore(today, "day"));

  //     return parsedDate.isBefore(today, "day");
  //   }

  //   if (isDateInThePast(startDate)) {
  //     console.log("Date is in the past. Calling removeDate...");
  //     removeDate();
  //   } else {
  //     console.log("Date is not in the past.");
  //   }
  // }, [startDate, removeDate]);

  return (
    <div className="w-full">
      <span className=" font-semibold text-sm">{label}</span>
      <div dir="ltr" className="w-full h-11 pt-1">
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={i18n.language}
        >
          <DatePicker
            label={t(title)}
            className="w-full  "
            onChange={(e) => handleDate(e)}
            format="YYYY-MM-DD"
            disablePast
            value={startDate ? dateStorge : null}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
}
