import axios from "axios";
const FlightURL = "https://apiflight.flymoons.com";
// export const generateSignature = async () => {
//   try {
//     const response = await axios.post(
//       "https://b2bapiutils.benzyinfotech.com/Utils/Signature",
//       {
//         MerchantID: "300",
//         ApiKey: "kXAY9yHARK",
//         ClientID: "bitest",
//         Password: "staging@1",
//         AgentCode: "",
//         BrowserKey: "caecd3cd30225512c1811070dce615c1",
//         Key: "ef20-925c-4489-bfeb-236c8b406f7e",
//       }
//     );

//     return response.data;
//   } catch (error) {
//     console.log(error);
//   }
// };
export const generateSignature = async () => {
  try {
    const response = await axios.post(`${FlightURL}/flight/signature`);
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const initiateExpressSearch = async (searchData) => {
  const token = localStorage.getItem("Token");

  try {
    // Retrieve Token from local storage

    if (!token) {
      console.warn("Token is missing. Ensure you have a valid token.");
      return;
    }

    // Call the API with the Token in the Authorization header
    const response = await axios.post(
      `${FlightURL}/flight/express-search`,
      searchData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          // Include the token in headers
        },
      }
    );

    if (response.data.TUI) {
      console.log("ExpressSearch Successful: ", response.data);

      return response.data;
    } else {
      console.warn("No TUI received. Check the response.");
    }
  } catch (error) {
    console.error("Error initiating ExpressSearch: ", error.message);
  }
};

export const fetchSearchResults = async (TUI) => {
  const token = localStorage.getItem("Token");
  const ClientID = localStorage.getItem("ClientID");

  if (!TUI || !ClientID) {
    throw new Error(
      "TUI or ClientID is missing. Please initiate a search first."
    );
  }

  try {
    const response = await axios.post(
      `${FlightURL}/flight/get-exp-search`,
      { TUI, ClientID },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data; // Save the search results
  } catch (error) {
    throw new Error("Error fetching search results:", error.message);
  }
};
export const fetchSmartPrice = async (payload) => {
  const token = localStorage.getItem("Token");

  if (!token) {
    throw new Error("Authorization token is required.");
  }

  try {
    const response = await axios.post(
      `${FlightURL}/flight/smart-pricer`, // Replace with your actual endpoint
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.Message) {
      return response.data?.Data;
    } else {
      throw new Error("Failed to fetch Smart Price details.");
    }
  } catch (error) {
    console.error("Error in fetchSmartPrice:", error.message);
    throw new Error(
      error.response?.data?.message || error.message || "An error occurred."
    );
  }
};

export const fetchGetPrice = async (TUI) => {
  const token = localStorage.getItem("Token");
  const ClientID = localStorage.getItem("ClientID");
  if (!TUI) {
    throw new Error("TUI, ClientID, and Authorization token are required.");
  }

  try {
    const response = await axios.post(
      `${FlightURL}/flight/get-spricer`, // Replace with your actual endpoint
      { TUI, ClientID },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data?.Data;
  } catch (error) {
    console.error("Error in fetchGetPrice:", error.message);
    throw new Error(error || "An error occurred.");
  }
};
