import React from "react";
import { useSearchFlights } from "../../context/SearchFlightsContext";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import useLocation from "../search/useLocation";

export default function Header({ origin, destination }) {
  return (
    <div>
      <Directions origin={origin} destination={destination} />
    </div>
  );
}

const Directions = ({ origin, destination }) => {
  const { i18n } = useTranslation();
  const [cityOrigin, airportOrigin, isLoadingOrigin] = useLocation(origin);
  const [cityDestination, airport, isLoading] = useLocation(destination);

  return (
    <div className="flex items-center justify-start gap-3 font-semibold">
      {/* Origin Loading or Content */}
      {isLoadingOrigin ? (
        <div className="animate-pulse">
          <p className="h-4 bg-gray-300 rounded w-16"></p>
        </div>
      ) : (
        <p>{cityOrigin}</p>
      )}

      {/* Arrow Based on Language */}
      {i18n.language === "ar" ? (
        <FaArrowLeftLong className="text-sm" />
      ) : (
        <FaArrowRightLong className="text-sm" />
      )}

      {/* Destination Loading or Content */}
      {isLoading ? (
        <div className="animate-pulse">
          <p className="h-4 bg-gray-300 rounded w-16"></p>
        </div>
      ) : (
        <p>{cityDestination}</p>
      )}
    </div>
  );
};
