import React, { useState } from "react";
import Input from "../../ui/Input";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import FormRow from "../../ui/FormRow";
import { emailRegex, nameRegex, passportOrIdRegex } from "../../utils/regex";
import Select from "../../ui/Select";
import { countries, transformToTraveller } from "../../utils/helper";
import BirthDateSelect from "../../ui/BirthDateSelect";
import GenderButton from "../../ui/GenderButton";
import PhoneInput from "../../ui/PhoneInput";
import { handlePayment } from "../../services/apiPayment";
import { useTranslation } from "react-i18next";
import { createItinerary } from "../../services/apiBooking";
export default function CustomerForm({ flights }) {
  const { t, i18n } = useTranslation();
  const ClientID = localStorage.getItem("ClientID");

  let [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const { register, formState, getValues, handleSubmit, reset } = useForm();
  const { errors } = formState;
  const booking_token = localStorage.getItem("Token");
  const sessionId = searchParams.get("sessionId");
  const onSubmit = async (formValues) => {
    console.log(formValues);
    const travellers = transformToTraveller(formValues);
    console.log(travellers);
    const payload = {
      TUI: flights?.TUI,
      Travellers: travellers,
      NetAmount: flights?.NetAmount,
      ClientID,
    };

    try {
      const response = await createItinerary({
        TUI: flights?.TUI,
        ClientID,
        NetAmount: flights?.NetAmount,
        Travellers: [
          // {
          //   ID: 1,
          //   PaxID: "YWdr",
          //   Operation: "0",
          //   Title: "Mr",
          //   FName: formValues?.firstName,
          //   LName: formValues?.lastName,
          //   Email: formValues?.email,
          //   PMobileNo: formValues?.phone,
          //   Age:
          //     new Date().getFullYear() -
          //     parseInt(formValues["birthday-year"], 10),
          //   DOB: `${formValues["birthday-year"]}-${formValues[
          //     "birthday-month"
          //   ].padStart(2, "0")}-01`,
          //   Country: formValues?.nationality,
          //   Gender: "M",
          //   PTC: "ADT",
          //   Nationality: "",
          //   PassportNo: "HM8888HJJ6K",
          //   PLI: "",
          //   PDOI: "",
          //   PDOE: "",
          //   VisaType: "VISITING VISA",
          //   EmigrationCheck: false,
          //   isOptionSelected: false,
          //   ApproverManagers: {
          //     Managers: [],
          //     Type: "",
          //   },
          // },
          travellers,
        ],
      });
      const itinerary = response.Data;
      const payment = await handlePayment(
        formValues,
        "1154652898",
        itinerary?.GrossAmount,
        itinerary?.NetAmount,
        itinerary?.TUI,
        itinerary?.TransactionID,
        booking_token
      );
      console.log("Itinerary created:", itinerary);
      console.log(payment);
    } catch (error) {
      console.error("Error creating itinerary:", error);
    }
    // setIsLoading(true);
  };

  const handleActive = (setp) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("activeStep", setp);
    setSearchParams(newSearchParams);
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white  py-5 w-full border rounded-md">
          <Header t={t} />
          <div className="px-5 flex flex-col ">
            <Gender />
            <Row>
              <FormRow label={t("customerForm.firstname.label")} error={errors}>
                <Input
                  {...register("firstName", {
                    required: t("customerForm.firstname.required"),
                    pattern: {
                      value: nameRegex,
                      message: t("customerForm.firstname.invalidFormat"), // Error message if regex fails
                    },
                  })}
                  placeholder={t("customerForm.firstname.placeholder")}
                  id="firstName"
                  err={errors["firstName"] ? false : true}
                />
              </FormRow>

              <FormRow label={t("customerForm.lastname.label")} error={errors}>
                <Input
                  {...register("lastName", {
                    required: t("customerForm.lastname.required"),
                    pattern: {
                      value: nameRegex,
                      message: t("customerForm.lastname.invalidFormat"), // Error message if regex fails
                    },
                  })}
                  register={register}
                  placeholder={t("customerForm.lastname.placeholder")}
                  err={errors["lastName"] ? false : true}
                  id="lastName"
                />
              </FormRow>
            </Row>

            <Row>
              <FormRow
                label={t("customerForm.nationality.label")}
                error={errors}
              >
                <Select
                  {...register("nationality", {
                    required: t("customerForm.nationality.required"),
                    pattern: {
                      value: nameRegex,
                      message: t("customerForm.nationality.invalidFormat"), // Error message if regex fails
                    },
                  })}
                  err={errors["nationality"] ? false : true}
                  id="nationality"
                >
                  <option value="" disabled selected>
                    {t("customerForm.nationality.placeholder")}
                  </option>
                  {countries.map((country) => (
                    <option value={country.code} key={country.code}>
                      {country.name[i18n.language]} ({country.code})
                    </option>
                  ))}
                </Select>
              </FormRow>
              <BirthDateSelect
                label={t("customerForm.birthday.label")}
                register={register}
                errors={errors}
                id="birthday"
                t={t}
              />
            </Row>
            <Row>
              <FormRow
                label={t("customerForm.passportOrIdNumber.label")}
                error={errors}
              >
                <Input
                  {...register("id", {
                    required: t("customerForm.passportOrIdNumber.required"),
                    pattern: {
                      value: passportOrIdRegex,
                      message: t(
                        "customerForm.passportOrIdNumber.invalidFormat"
                      ), // Error message if regex fails
                    },
                  })}
                  register={register}
                  placeholder={t("customerForm.passportOrIdNumber.placeholder")}
                  err={errors["id"] ? false : true}
                  id="id"
                />
              </FormRow>
              <BirthDateSelect
                label={t("customerForm.passportOrIdExpiryDate.label")}
                register={register}
                errors={errors}
                id={"passportOrIdExpiryDate"}
                t={t}
              />
            </Row>
          </div>
        </div>
        <Contact register={register} errors={errors} />{" "}
        <div className="flex items-center justify-end">
          <button
            className="flex items-center bg-blue-700 text-white px-5 rounded-md font-semibold  py-4 justify-center xl:w-auto w-full mt-5"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              t("paymentRedirect")
            ) : (
              <>
                <div className="flex items-center gap-1 font-semibold  ">
                  {t("pay")}
                  <p className="flex items-center gap-[1px]">
                    {flights?.GrossAmount?.toFixed(2)}
                    <span className="text-sm">({flights?.CurrencyCode})</span>
                  </p>
                </div>
              </>
            )}
          </button>
        </div>
      </form>
    </>
  );
}

const Header = ({ t }) => {
  return (
    <div className="text-lg font-semibold border-b px-5 pb-5 flex items-center justify-between">
      <p>{t("mainPassenger")}</p>
      <p className="text-base text-gray-800">{t("adult")}:1</p>
    </div>
  );
};

const Gender = () => {
  const [value, setValue] = useState("");
  const { t } = useTranslation();
  const genders = t("genders", { returnObjects: true });

  return (
    <div className="flex items-center pt-5 gap-5">
      {genders.map((gender) => (
        <GenderButton
          active={gender.value === value}
          onClick={() => setValue(gender.value)}
        >
          {gender.name}
        </GenderButton>
      ))}
    </div>
  );
};

const Contact = ({ errors, register }) => {
  const { t } = useTranslation();

  return (
    <div className="pt-5 mt-5 bg-white border rounded-md mb-5">
      <div className="text-lg font-semibold border-b  pb-5 flex items-center justify-between">
        <p className="px-5">{t("contactDetails")}</p>
      </div>
      <div className=" py-5 px-5">
        <Row>
          <FormRow label={t("customerForm.email.label")} error={errors}>
            <Input
              {...register("email", {
                required: t("customerForm.email.required"),
                pattern: {
                  value: emailRegex,
                  message: "Invalid name format", // Error message if regex fails
                },
              })}
              placeholder={t("customerForm.email.placeholder")}
              id="email"
              err={errors["email"] ? false : true}
            />
          </FormRow>
          <PhoneInput
            name={"phone"}
            label={t("customerForm.phone.label")}
            register={register}
            error={errors}
            t={t}
          />
        </Row>
      </div>
    </div>
  );
};

const Row = ({ children }) => {
  return (
    <div className="flex items-center justify-between w-full xl:gap-8 xl:flex-row flex-col">
      {children}
    </div>
  );
};
