import React, { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";
import Price from "../features/booking/Price";
import Stepper from "../ui/Stepper";
import Spinner from "../ui/Spinner";
import CustomertInfo from "../features/booking/CustomertInfo";
import Payment from "../features/booking/Payment";
import FlightsDetails from "../features/booking/FlightsDetails";
import CustomerForm from "../features/booking/CustomerForm";
import { checkFlights } from "../services/apiFlights";
import { fetchGetPrice, fetchSmartPrice } from "../services/akbarApi";
import NoResult from "../ui/NoResult";

export default function BookingPayment() {
  let [searchParams] = useSearchParams();
  const params = new URLSearchParams(searchParams);
  const [flights, setFlights] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const bookingId = params.get("bookingId");
  const ClientID = localStorage.getItem("ClientID");
  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      const tripsArray = JSON.parse(sessionStorage.getItem("trips") || "[]");

      const payload = {
        Trips: tripsArray,
        ClientID: ClientID,
        Mode: "AS",
        Options: "A",
        Source: "SF",
        TripType: "ON",
      };

      try {
        const response = await fetchSmartPrice(payload);
        const res = await fetchGetPrice(response?.TUI);
        console.log("getPrice", res);
        setFlights(res.Code === "200" || "1500" ? res : null);
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
      // setIsLoading(true);
      // try {
      //   const res = await fetchGetPrice(bookingId);
      //   console.log("getPrice", res);
      //   setFlights(res.Code === "200" || "1500" ? res : null);
      // } catch (err) {
      //   console.log(err);
      // } finally {
      //   setIsLoading(false);
      // }
    }
    getData();
  }, [bookingId]);

  if (isLoading) return <Spinner />;
  if (!flights) return <NoResult />;
  return (
    <div className="grid xl:grid-cols-[1fr,0.4fr]  gap-10 relative">
      <div className="flex flex-col gap-5">
        <FlightsDetails flights={flights} />
        <CustomerForm flights={flights} />
        {/* {step == 1 ? <CustomerForm flights={flights} /> : <Payment />} */}
      </div>
      <Price flights={flights} />
    </div>
  );
}
