import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCityById } from "../../services/apiPlanes";

export default function useLocation(code) {
  const [cityName, setCity] = useState("");
  const [airport, setAirport] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { i18n, t } = useTranslation();
  const lan = i18n.language === "ar" ? "ar-AE" : "en-US";
  useEffect(() => {
    async function getCity() {
      setIsLoading(true);
      try {
        const locationData = await getCityById(code, lan);
        console.log(locationData);
        setCity(locationData.city.name);
        setAirport(locationData?.name);
      } catch (err) {
        setCity("");
      } finally {
        setIsLoading(false);
      }
    }
    getCity();
  }, [code, lan]);
  // const memoizedCityName = useMemo(() => cityName, [cityName]);

  return [cityName, airport, isLoading];
}
