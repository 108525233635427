import React, { lazy, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import AppLayout from "./ui/AppLayout";
import { useTranslation } from "react-i18next";
import NotFound from "./pages/NotFound";
import { isMobile } from "react-device-detect";
import SearchFlightsProvider from "./context/SearchFlightsContext";
import { SearchTypeContext } from "./context/searchTypeContext";
import PaymentForm from "./pages/PaymentForm";
import GlobalStyles from "./style/GlobalStyles";
import BookingPayment from "./pages/BookingPayment";
import PaymentSuccess from "./ui/PaymentSuccess";
import PaymentFailure from "./ui/PaymentFailure";
import FligthMobile from "./pages/mobile/Fligths";
import { generateSignature } from "./services/akbarApi";
const Home = lazy(() => import("./pages/Home"));
const Booking = lazy(() => import("./pages/Booking"));
const HomeMobile = lazy(() => import("./pages/mobile/Home"));
const BannerMobile = lazy(() => import("./features/home/mobile/Banner"));
// const FligthMobile = lazy(() => import("./pages/mobile/Fligths"));
const Search = lazy(() => import("./pages/Search"));
const HotelsResults = lazy(() => import("./pages/HotelsResults"));
const FlightForm = lazy(() => import("./features/home/flight/SearchBar"));
const HotelForm = lazy(() => import("./features/home/hotels/SearchBar"));
const Offers = lazy(() => import("./pages/Offers"));
const OfferDetails = lazy(() => import("./pages/OfferDetails"));
const Destination = lazy(() => import("./pages/Destination"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Article = lazy(() => import("./pages/Article"));
const ArticleDetails = lazy(() => import("./pages/ArticleDetails"));
const ArticleCatogray = lazy(() => import("./pages/ArticleCatogray"));

function App() {
  const { i18n } = useTranslation();
  // useEffect(() => {
  //   document.documentElement.lang = i18n.language;
  //   document.head.title =
  //     i18n.language === "ar"
  //       ? "NewSky وكاله سماء جديده للسفر والسياحة|مكة المكرمة|السعودية"
  //       : "NewSky Agency for Travel and Tourism|makkah|Saudi Arabia";
  // }, [i18n.language]);

  return (
    <>
      <SearchFlightsProvider>
        <SearchTypeContext>
          <Routes>
            <Route element={<AppLayout />}>
              <Route path="/" element={isMobile ? <HomeMobile /> : <Home />}>
                <Route
                  path=""
                  element={isMobile ? <BannerMobile /> : <FlightForm />}
                />
                <Route
                  path="flights"
                  element={isMobile ? <FligthMobile /> : <FlightForm />}
                />
                <Route path="hotels" element={<HotelForm />} />
              </Route>
              <Route
                path="/flight/search/:origin/:destination/:date"
                element={<Search />}
              />{" "}
              <Route path="/hotel/search" element={<HotelsResults />} />
              <Route path="/booking/:bookingID" element={<Booking />}>
                <Route path="" element={<BookingPayment />} />
                <Route path="success" element={<PaymentSuccess />} />
                <Route path="failure" element={<PaymentFailure />} />
              </Route>
              <Route path="/offers" element={<Offers />} />
              <Route path="/offer/:offerId" element={<OfferDetails />} />
              <Route
                path="/destination/:destinationId"
                element={<Destination />}
              />
              <Route path="/article" element={<Article />} />
              <Route path="/article/:articleId" element={<ArticleDetails />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route
                path="/article/catogray/:catograyId"
                element={<ArticleCatogray />}
              />
              <Route path="*" element={<NotFound />} />
              <Route path="/payment" element={<PaymentForm />} />
            </Route>
          </Routes>
        </SearchTypeContext>
      </SearchFlightsProvider>
    </>
  );
}

export default App;
