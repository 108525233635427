// export const handlePayment = async (
//   formData,
//   booking_token,
//   sessionId,
//   flights,
//   setIsLoading
// ) => {
//   try {
//     const response = await fetch(
//       "https://flymoon-backend.vercel.app/payment/create-payment",
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json", // Ensure the server knows you're sending JSON
//         },
//         body: {
//           TUI: "a9d10da40-0623-4028-8900-0d0e0c650c09|acf92b8b-0046-48e1-b075-b9d3f56b8e24|20241126025353",
//           ServiceEnquiry: "",
//           ContactInfo: {
//             Title: "",
//             FName: "",
//             LName: "",
//             Mobile: "8590055610",
//             DestMob: "8590055610",
//             Phone: "",
//             Email: "robin@benzyinfotech.com",
//             Language: "",
//             Address: "MRRA 4  EDAPPALLY  Edappally , EDAPPALLY , Edappally",
//             CountryCode: "IN",
//             MobileCountryCode: "+91",
//             DestMobCountryCode: "+91",
//             State: "Kerala",
//             City: "Cochin",
//             PIN: "6865245",
//             GSTCompanyName: "",
//             GSTTIN: "",
//             GstMobile: "",
//             GSTEmail: "",
//             UpdateProfile: false,
//             IsGuest: false,
//             SaveGST: false,
//           },
//           DestinationContactInfo: {
//             Address1: "",
//             Address2: "",
//             City: "",
//             Mobile: "",
//             Phone: "",
//             Email: "",
//             CountryCode: "",
//             MobileCountryCode: "+91",
//             State: "",
//             PIN: "",
//           },
//           Travellers: [
//             {
//               ID: 1,
//               PaxID: "YWdr",
//               Operation: "0",
//               Title: "Mr",
//               FName: "TESTA",
//               LName: "TESTAB",
//               Email: "mails@mail.com",
//               PMobileNo: "",
//               Age: 22,
//               DOB: "2002-01-27",
//               Country: "",
//               Gender: "M",
//               PTC: "ADT",
//               Nationality: "",
//               PassportNo: "HM8888HJJ6K",
//               PLI: "",
//               PDOI: "",
//               PDOE: "",
//               VisaType: "VISITING VISA",
//               EmigrationCheck: false,
//               isOptionSelected: false,
//               ApproverManagers: {
//                 Managers: [],
//                 Type: "",
//               },
//               DocumentType: "",
//             },
//           ],
//           PLP: [],
//           SSR: null,
//           CrossSell: [],
//           CrossSellAmount: 0,
//           EnableFareMasking: false,
//           SSRAmount: 0,
//           ClientID: "FVI6V120g22Ei5ztGK0FIQ==",
//           DeviceID: "",
//           AppVersion: "",
//           AgentTourCode: "",
//           NetAmount: 16192.0,
//           BRulesAccepted: "",
//         },
//       }
//     );
//     const data = await response.json();

//     if (data.redirect_url) {
//       window.location.href = data.redirect_url;
//     }
//   } catch (error) {
//     console.error("Error creating payment: ", error);
//   }
// };
// export const handlePayment = async (
//   formData,
//   booking_token,
//   sessionId,
//   flights,
//   setIsLoading
// ) => {
//   try {
//     const response = await fetch(
//       "https://flymoon-backend.vercel.app/payment/create-payment",
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json", // Ensure the server knows you're sending JSON
//         },
//         body: JSON.stringify({
//           name: formData.firstName + " " + formData.lastName, // Concatenate first and last names
//           email: formData.email,
//           phone: formData.phone,
//           country: formData?.nationality, // Use optional chaining in case nationality is undefined
//           sessionId: sessionId,
//           amount: flights?.conversion?.adults_price, // Use optional chaining for potential undefined values
//           booking_token,
//         }),
//       }
//     );
//     const data = await response.json();

//     if (data.redirect_url) {
//       window.location.href = data.redirect_url;
//     }
//   } catch (error) {
//     console.error("Error creating payment: ", error);
//   }
// };
export const handlePayment = async (
  formData,
  sessionId,
  GrossAmount,
  NetAmount,
  TUI,
  TransactionID,
  booking_token
) => {
  try {
    const response = await fetch(
      "http://localhost:3000/payment/create-payment",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Ensure the server knows you're sending JSON
        },
        body: JSON.stringify({
          name: formData.firstName + " " + formData.lastName, // Concatenate first and last names
          email: formData.email,
          phone: formData.phone,
          country: formData?.nationality, // Use optional chaining in case nationality is undefined
          sessionId: sessionId,
          GrossAmount, // Use optional chaining for potential undefined values
          TUI,
          NetAmount,
          TransactionID,
          booking_token,
        }),
      }
    );
    const data = await response.json();
    console.log(data);
    // if (data.redirect_url) {
    //   window.location.href = data.redirect_url;
    // }
    console.log(data.redirect_url);
  } catch (error) {
    console.error("Error creating payment: ", error);
  }
};
