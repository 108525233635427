import { useTranslation } from "react-i18next";
const SearchButton = ({ onSearch }) => {
  const { t } = useTranslation();

  return (
    <button
      className="flex items-center bg-blue-700 text-white px-5 rounded-md h-[50px] justify-center xl:w-auto w-full xl:mt-[17px] mt-5"
      onClick={() => onSearch?.()}
    >
      {t("search_button")}
    </button>
  );
};
export default SearchButton;
