import React, { useState } from "react";
import { useSearchType } from "../../../context/searchTypeContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSearchFlights } from "../../../context/SearchFlightsContext";
import { initiateExpressSearch } from "../../../services/akbarApi";

export default function useSearch() {
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state

  const [searchParams, setSearchParams] = useSearchParams();

  const TUI = localStorage.getItem("TUI"); // Retrieve TUI from localStorage
  const clientID = localStorage.getItem("clientId"); // Retrieve ClientID from localStorage

  const navigate = useNavigate();

  const {
    origin,
    destination,
    outboundDate,
    cabinClass,
    inboundDate,
    adults,
    childrenPass: children,
    infants,
  } = useSearchFlights();

  const { searchType } = useSearchType();

  const validateSearchParams = () => {
    if (!origin || !destination || !outboundDate) {
      setError("Origin, destination, and outbound date are required.");
      return false;
    }
    if (!clientID || !TUI) {
      setError("ClientID and TUI are missing. Ensure proper initialization.");
      return false;
    }
    return true;
  };

  const handleSearch = async (e) => {
    e && e.preventDefault();
    setError(null); // Reset error state
    setLoading(true); // Set loading state

    // Validate search parameters
    if (validateSearchParams()) {
      setLoading(false);
      return;
    }

    // Initiate the ExpressSearch

    // Update search parameters
    const params = new URLSearchParams(searchParams);
    // params.set("origin", origin);
    // params.set("destination", destination);
    // params.set("outboundDate", outboundDate);
    params.set("adults", adults);
    params.set("children", children);
    params.set("infants", infants);
    params.set("cabinClass", cabinClass);
    // params.set("type", searchType);

    // params.set("searchId", searchId.TUI);
    const inboundDateUrl =
      inboundDate && searchType === "return" ? `_${inboundDate}` : "";

    setSearchParams(params); // Set the updated search params

    // Navigate to the search results page
    navigate(
      `/flight/search/${origin}/${destination}/${outboundDate}${inboundDateUrl}?${params.toString()}`
    );
    setLoading(false);
  };

  return { handleSearch, loading, error };
}
