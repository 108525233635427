import React from "react";
import { useNavigate } from "react-router-dom";

const NoResult = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen text-center gap-4">
      <h1 className="text-xl font-bold text-red-600">
        Oops! Something went wrong.
      </h1>
      <p className="text-gray-600">
        We couldn't find any results for your search. You can try searching
        again or return to the home page.
      </p>
      <div className="flex gap-4">
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          onClick={() => navigate("/")} // Navigate to home page
        >
          Back to Search
        </button>
      </div>
    </div>
  );
};

export default NoResult;
