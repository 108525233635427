import axios from "axios";
import { da } from "date-fns/locale";
const token = localStorage.getItem("Token");
const clientID = localStorage.getItem("clientId");
const TUI = localStorage.getItem("TUI");

export const fetchSearch = async (searchData) => {
  let params = {
    ADT: 1, // Number of Adult Passengers
    CHD: 0, // Number of Child Passengers
    INF: 0, // Number of Infant Passengers
    Cabin: "E", // Cabin Class (e.g., "E" for Economy, "B" for Business)
    Source: "LV", // Source identifier for the request
    Mode: "AS", // Mode, could represent different search modes or channels
    ClientID: clientID, // Unique Client ID
    TUI, // Transaction Unique Identifier, empty on initial request
    FareType: "ON", // Fare Type (e.g., "ON" for Online fares)
    Trips: [searchData],
    Parameters: {
      Airlines: "", // Optional specific airline preference, empty for all airlines
      GroupType: "", // Group type, if any (e.g., corporate, family)
      Refundable: "", // Leave empty or specify if only refundable fares are required
      IsDirect: true, // Set to true for direct flights only, false to include layovers
      IsStudentFare: false, // Set to true if looking for student fare options
      IsNearbyAirport: false, // Set to true to include nearby airports in the search
    },
  };

  try {
    const response = await axios.post(
      "https://b2bapiflights.benzyinfotech.com/flights/ExpressSearch",
      params,
      {
        headers: {
          accept: "application/json",
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const fetchSearchKiwi = async (searchData) => {
  let params = {
    ...searchData,
    adults: 1,

    adult_hold_bag: "1",
    adult_hand_bag: "1",
    partner_market: "sa",
    max_stopovers: 0,
    curr: "SAR",
    max_sector_stopovers: 1,
    limit: 30,
  };

  try {
    const response = await axios.get(
      "https://api.tequila.kiwi.com/v2/search/",
      {
        params,
        headers: {
          accept: "application/json",
          apikey: "yTrA8Iq0rpFUzY3TgRRBOXFnljTlt2aM",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getExpSearch = async (TUI) => {
  try {
    const response = await axios.post(
      "https://b2bapiflights.benzyinfotech.com/flights/GetExpSearch",
      {
        ClientID: clientID,
        TUI,
      },
      {
        headers: {
          accept: "application/json",
          Authorization: token,
        },
      }
    );

    // Parse the response
    const data = response.data;
    return data;
    // Check if the response meets the criteria to set intel to true
    // if (
    //   data.Code === "200" &&
    //   data.Completed === "True" &&
    //   Array.isArray(data.Trips) &&
    //   data.Trips.length > 0
    // ) {
    //   console.log("Intel condition met. Setting intel to true.");
    //   return { intel: true, data }; // Return both intel and data if needed
    // } else {
    //   console.log("Intel condition not met.");
    //   return { intel: false, data }; // Return intel as false with the response data
    // }
  } catch (error) {
    console.error("Error in getExpSearch:", error);
    return { intel: false, error: error.message }; // Handle error scenarios
  }
};
// export const checkFlights = async (TUI) => {
//   try {
//     const response = await axios.post(
//       "https://b2bapiflights.benzyinfotech.com/flights/SmartPricer",
//       {
//         Trips: [
//           {
//             Amount: 0,
//             Index: "6E|1",
//             OrderID: 1,
//             TUI: "cd3c87ee-b170-43dd-b9dc-b689fdf50f7d|9656c4e6-0c3f-48db-a670-64055853edc9|20241121002948",
//           },
//         ],
//         ClientID: "FVI6V120g22Ei5ztGK0FIQ==",
//         Mode: "SY",
//         Options: "A",
//         Source: "CF",
//         TripType: "ON",
//       },
//       {
//         headers: {
//           accept: "application/json",
//           Authorization: token,
//         },
//       }
//     );

//     // Parse the response
//     const data = response.data;
//     return data;
//     // Check if the response meets the criteria to set intel to true
//     // if (
//     //   data.Code === "200" &&
//     //   data.Completed === "True" &&
//     //   Array.isArray(data.Trips) &&
//     //   data.Trips.length > 0
//     // ) {
//     //   console.log("Intel condition met. Setting intel to true.");
//     //   return { intel: true, data }; // Return both intel and data if needed
//     // } else {
//     //   console.log("Intel condition not met.");
//     //   return { intel: false, data }; // Return intel as false with the response data
//     // }
//   } catch (error) {
//     console.error("Error in getExpSearch:", error);
//     return { intel: false, error: error.message }; // Handle error scenarios
//   }
// };

// export const fetchSearch = async (searchData) => {
//   let params = {
//     ...searchData,
//     adults: 1,
//     adult_hold_bag: "1",
//     adult_hand_bag: "1",
//     partner_market: "sa",
//     max_stopovers: 0,
//     curr: "SAR",
//     max_sector_stopovers: 1,
//     limit: 500,
//   };

//   try {
//     const response = await axios.get(
//       "https://api.tequila.kiwi.com/v2/search/",
//       {
//         params,
//         headers: {
//           accept: "application/json",
//           apikey: "DzzphWUXkgDUThzrRUWVHwr0ccQqRpNu",
//         },
//       }
//     );
//     return response.data;
//   } catch (error) {
//     return error;
//   }
// };

const API_BASE_URL = "https://b2bapiflights.benzyinfotech.com/flights";

export const checkFlights = async (TUI, index) => {
  try {
    // Step 1: Call SmartPricer endpoint
    const smartPricerResponse = await axios.post(
      `${API_BASE_URL}/SmartPricer`,
      {
        Trips: [
          {
            Amount: 0,
            Index: index, // Replace with the correct index from your data
            OrderID: 1,
            TUI,
          },
        ],
        ClientID: clientID, // Replace with your actual ClientID
        Mode: "AS", // Synchronous mode for accurate data
        Options: "A",
        Source: "SF", // Cache First for a balance between speed and accuracy
        TripType: "ON", // One Way Trip
      },
      {
        headers: {
          Accept: "application/json",
          Authorization: token, // Add your token dynamically
        },
      }
    );

    // Parse SmartPricer response
    const smartPricerData = smartPricerResponse.data;
    console.log(smartPricerData);
    // if (!smartPricerData?.Trips || !smartPricerData?.Trips?.length) {
    //   throw new Error("No trips found in SmartPricer response");
    // }

    // Step 2: Call GetSPricer for live details
    const getSPricerResponse = await axios.post(
      `${API_BASE_URL}/GetSPricer`,
      {
        TUI: smartPricerData?.TUI,
        ClientID: clientID,
      },
      {
        headers: {
          Accept: "application/json",
          Authorization: token, // Add your token dynamically
        },
      }
    );

    // Parse GetSPricer response
    const getSPricerData = getSPricerResponse.data;
    console.log(getSPricerData);
    // Combine or process the data as required
    return getSPricerData;
  } catch (error) {
    console.error("Error checking flights:", error.message || error);
    throw error; // Re-throw the error for the caller to handle
  }
};
