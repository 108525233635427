import React from "react";
import Logo from "./Logo";
import Input from "./Input";
import google from "../assets/google.png";
import InputLogin from "./InputLogin";
import { HiOutlineMail } from "react-icons/hi";
import { MdOutlineLock } from "react-icons/md";
import { IoMdClose } from "react-icons/io";

export default function SignIn({ onLogin }) {
  return (
    <div className=" fixed w-full h-screen top-0 left-0 flex items-center justify-center bg-gray-600/30 rounded-md z-50">
      <LoginForm onLogin={onLogin} />
    </div>
  );
}

const LoginForm = ({ onLogin }) => {
  return (
    <div className="w-[360px] bg-white flex-col items-center justify-center px-5 py-5 rounded-lg">
      <div
        className=" bg-gray-100 w-fit p-1 rounded-md cursor-pointer"
        onClick={() => onLogin(false)}
      >
        <IoMdClose className="w-5 h-5" />
      </div>
      <Logo />
      <h2 className="text-center text-2xl pt-5">تسجيل الدخول</h2>
      <form action="" className="flex flex-col gap-5 mt-5">
        <div className="flex flex-col gap-8">
          <div className="flex items-center bg-[#fdf4f4] px-2 rounded-md">
            <InputLogin placeholder="Email" className="text-end" />
            <HiOutlineMail className="w-6 h-6 text-gray-400" />
          </div>
          <div className="flex items-center bg-[#fdf4f4] px-2 rounded-md">
            <InputLogin placeholder="Password" className="text-end" />
            <MdOutlineLock className="w-6 h-6 text-gray-400" />
          </div>
        </div>

        <p className="text-blue-900 text-sm">هل نسيت كلمة السر ؟</p>
        <div className="w-full h-[1px] bg-gray-400 relative">
          <div className=" absolute  text-center w-full top-[-10px] flex items-center justify-center">
            <p className="bg-white w-fit px-2 text-sm font- text-gray-700">
              او عن طريق
            </p>
          </div>
        </div>
        <LoginOptions />
        <LoginButton />
      </form>
    </div>
  );
};

const LoginOptions = () => {
  return (
    <div className="flex items-center justify-center ">
      <div className="flex items-center justify-center w-8 h-8 bg-gray-100 p-1 rounded-lg">
        <img src={google} alt="google" />
      </div>
    </div>
  );
};

const LoginButton = () => {
  return (
    <button className="flex items-center bg-blue-700 text-white px-5 rounded-md h-[45px] justify-center xl:w-auto w-full xl:mt-0 ">
      تسجيل الدخول
    </button>
  );
};
