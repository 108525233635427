import styled from "styled-components";

const InputLogin = styled.input`
  /* border: 1px solid ${(props) => (props.err ? "#d1d5db" : "#d1d5db")}; */
  background-color: #fdf4f4;
  border-radius: 7px;
  padding: 0.6rem 1.2rem;
  /* box-shadow: 0px 0.6rem 2.4rem rgba(0, 0, 0, 0.06); */
  width: 100%;
  &:focus {
    outline: 2px solid ${(props) => (props.err ? "#4f46e5" : "#b91c1c")};
    border: none;
    outline-offset: -1px;
  }
  &::placeholder {
    font-size: 15px;
  }
`;

export default InputLogin;
