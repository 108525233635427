import React, { useState } from "react";
import Logo from "./Logo";
import MainNav from "./MainNav";
import { useTranslation } from "react-i18next";
import { HiOutlinePhone } from "react-icons/hi";
import { MdOutlineWhatsapp } from "react-icons/md";
import { IoClose, IoMenuSharp } from "react-icons/io5";
import NavList from "./NavList";
import SignIn from "./SignIn";

const Header = () => {
  const { t, i18n } = useTranslation();
  const [showNav, setShowNav] = useState(false);
  const [login, setLogin] = useState(false);
  const changeLanguage = (language) => {
    i18n.changeLanguage(i18n.language === "ar" ? "en" : "ar");
    // window.location.reload();
    setShowNav(false);
  };
  const lan = i18n.language === "ar" ? "en" : "ar";
  return (
    <>
      <header className="w-full fixed left-0 bg-white border-b z-50 top-0">
        {/* <div className="w-full text-white bg-[#3085d6] flex items-center top-0 z-50 h-[28px]">
          <NavTop />
        </div> */}
        <div className="max-w-screen-xl flex flex-wrap items-center mx-auto  py-3 lx:px-0 px-5  justify-between ">
          <div className="flex items-center gap-20">
            <Logo />
            <ul className="lg:flex items-center gap-5 hidden justify-center">
              {t("list", { returnObjects: true })?.map((item, i) => (
                <MainNav item={item} key={i} />
              ))}
            </ul>
          </div>

          <IoMenuSharp
            className="text-2xl lg:hidden "
            onClick={() => setShowNav(true)}
          />
          <div
            className={`w-full h-screen bg-white absolute top-0 pt-5 px-5 transition-all duration-100 lg:hidden ${
              showNav ? "block right-0 " : "hidden right-[100%]"
            }`}
          >
            <div className="flex items-center justify-between">
              <Logo />
              <IoClose className="text-2xl" onClick={() => setShowNav(false)} />
            </div>
            <ul className="flex items-end gap-10 flex-col pt-10">
              {t("list", { returnObjects: true }).map((item, i) => (
                <NavList item={item} key={i} onShowNav={setShowNav} />
              ))}
            </ul>
            <div className=" items-center gap-5 text-gray-600 flex  w-full border-b pt-8 pb-2 px-5">
              <p
                className="  cursor-pointer text-[#197e90] font-semibold  w-full"
                onClick={changeLanguage}
              >
                {lan}
              </p>
            </div>
          </div>

          <div className=" items-center gap-5 text-gray-600 lg:flex hidden">
            <p
              className="font-semibold  cursor-pointer"
              onClick={changeLanguage}
            >
              {lan}
            </p>
            <div
              className="border py-1 px-2 rounded-md"
              onClick={() => setLogin(true)}
            >
              <button>تسجيل الدخول</button>
            </div>
          </div>
        </div>
      </header>

      {login && <SignIn onLogin={setLogin} />}
    </>
  );
};

export default Header;
